import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'
import MobileSwiper from '../modules/mobile-swiper'

export default class Single extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      content: viewStorage.current.querySelector('.SingleContent .wswyg--content'),
      relatedArticlesSwiper: viewStorage.current.querySelector('.RelatedArticles .swiper.--mobile')
    }

    if (this.DOM.content) this.wysiwygWithVideosModule = new WysiwygWithVideos(this.DOM.content)
    if (this.DOM.relatedArticlesSwiper) this.relatedArticlesSwiperModule = new MobileSwiper(this.DOM.relatedArticlesSwiper)
  }

  onLeaveCompleted() {
    if (this.wysiwygWithVideosModule) this.wysiwygWithVideosModule.destroy()
    if (this.relatedArticlesSwiperModule) this.relatedArticlesSwiperModule.destroy()
  }
}
