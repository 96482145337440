/* global DEVMODE */

/*
  Global Storage Object
-------------------------------------------------- */
export const isDEVMODE = DEVMODE === 'development'

export const globalStorage = {
  'themeUrl': document.getElementById('siteInfos') ? document.getElementById('siteInfos').dataset.themePath : '/',
  'pageScrollTop': 0,
  'firstLoad': true,
  'browser': {
    'browserVersion': '',
    'isMobile': false,
    'isTouch': false,
    'isChrome': false,
    'isSafari': false,
    'isOpera': false,
    'isFirefox': false,
    'isIE': false
  },
  'isDarkMode': window.matchMedia('(prefers-color-scheme: dark)').matches,
  'tarteaucitron': window.tarteaucitron !== undefined ? window.tarteaucitron : null,
  'menuOpen': false
  // 'openMobileMenu': () => {},
  // 'closeMobileMenu': () => {}
}

export const viewStorage = {
  'current': document.querySelector('[data-taxi-view]'),
  'hasPrllx': false,
  'viewPrllx': null,
  'hasInview': false,
  'viewInView': null,
  'hasAnchors': false,
  'viewAnchors': null,
  'hasSmoothScroll': false,
  'viewScroll': null
}

export const domStorage = {
  'body': document.body,
  'header': document.getElementById('Header'),
  'menu': document.getElementById('Menu'),
  'app': document.getElementById('App'),
  'footer': document.getElementById('Footer'),
  'intro': document.getElementById('Intro'),
  'loader': document.getElementById('Loader'),
  'overlay': document.getElementById('Overlay'),
  'outdated': document.getElementById('Outdated'),
  'darkModeToggle': document.getElementById('DarkmodeToggle')
}

export const modulesStorage = {
  'scroll_indicator': null,
  'marquee': null,
  'scroll_reveal_quote': null,
  'scroll_highlight_content': null,
  'stack_cards': null,
  'page_header': null,
}
