/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'

import ArchiveArticles from './pages/archive-articles'
import FrontPage from './pages/front-page'
import PageAbout from './pages/page-about'
import PageContact from './pages/page-contact'
import PageMeeting from './pages/page-meeting'
import PageService from './pages/page-service'
import PageServiceChild from './pages/page-service-child'
import Single from './pages/single'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,

    archiveArticles: ArchiveArticles,
    frontPage: FrontPage,
    pageAbout: PageAbout,
    pageContact: PageContact,
    pageMeeting: PageMeeting,
    pageService: PageService,
    pageServiceChild: PageServiceChild,
    single: Single,
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
