import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import LineScrub from '../animations/line-scrub'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageService extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      serviceTargetSection: viewStorage.current.querySelector('.ServiceTarget'),
      expertiseSwiper: viewStorage.current.querySelector('.Expertise .swiper'),
      relatedServicesSwiper: viewStorage.current.querySelector('.RelatedServices .swiper.--mobile'),
      relatedServicesChildrenSwiper: viewStorage.current.querySelector('.RelatedServicesChildren .swiper.--mobile')
    }

    if (this.DOM.expertiseSwiper) this.expertiseSwiperModule = new WknSwiper(this.DOM.expertiseSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })
    if (this.DOM.relatedServicesSwiper) this.relatedServicesSwiperModule = new MobileSwiper(this.DOM.relatedServicesSwiper)
    if (this.DOM.relatedServicesChildrenSwiper) this.relatedServicesChildrenSwiperModule = new MobileSwiper(this.DOM.relatedServicesChildrenSwiper)

    if (this.DOM.serviceTargetSection) this.serviceTargetLineScrubModule = new LineScrub({ 
      section: this.DOM.serviceTargetSection,
      options: {
        start: 'top center',
        end: 'bottom center',
      }
    })
  }

  onLeaveCompleted() {
    if (this.serviceTargetLineScrubModule) this.serviceTargetLineScrubModule.destroy()
    if (this.relatedServicesSwiperModule) this.relatedServicesSwiperModule.destroy()
    if (this.relatedServicesChildrenSwiperModule) this.relatedServicesChildrenSwiperModule.destroy()
  }
}
