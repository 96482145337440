import { Renderer } from '@unseenco/taxi'

export default class PageMeeting extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {}
  }

  onLeaveCompleted() {}
}
