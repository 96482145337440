/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage, modulesStorage } from './_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { updateHeaderTheme } from './utils'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'
import { FormsManager } from '@wokine-agency/forms'

import ScrollIndicator from './modules/scroll-indicator'
import Marquee from './modules/marquee'
import PageHeader from './animations/page-header'
import ScrollRevealQuote from './animations/scroll-reveal-quote'
import StackCards from './animations/stack-cards'
import ScrollHighlightContent from './animations/scroll-highlight-content'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)

  // Intro
  GlobalIntro()

  // Update header theme
  updateHeaderTheme(viewStorage.current)

  // General modules
  modulesStorage.scroll_indicator = new ScrollIndicator()
  modulesStorage.marquee = new Marquee()
  modulesStorage.page_header = new PageHeader()
  modulesStorage.scroll_reveal_quote = new ScrollRevealQuote()
  modulesStorage.stack_cards = new StackCards()
  modulesStorage.scroll_highlight_content = new ScrollHighlightContent()

  // Forms
  initForms({
    DOM: domStorage.body
  })
}

export const onEnter = (to, trigger) => {
  domStorage.body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()

  // Forms
  initForms({
    DOM: viewStorage.current
  })

  // Update header theme
  updateHeaderTheme(viewStorage.current)
}

export const onEnterCompleted = (to, from, trigger) => {
  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': globalStorage.taxi.targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': globalStorage.taxi.targetLocation.href
    })
  }

  // Anchor
  if (globalStorage.taxi.targetLocation.hasHash) {
    if (document.querySelector(window.location.hash)) {
      gsap.to(window, {
        scrollTo: {
          y: window.location.hash,
          autoKill: false
        },
        overwrite: 'all',
        ease: 'expo.inOut',
        duration: 1.8,
      })
    }
  }

  // General modules
  modulesStorage.scroll_indicator = new ScrollIndicator()
  modulesStorage.marquee = new Marquee()
  modulesStorage.page_header = new PageHeader()
  modulesStorage.scroll_reveal_quote = new ScrollRevealQuote()
  modulesStorage.stack_cards = new StackCards()
  modulesStorage.scroll_highlight_content = new ScrollHighlightContent()
}

export const onLeave = (from, trigger) => {
  if (viewStorage.hasInview) viewStorage.viewInView.destroy()
  if (viewStorage.hasPrllx)  viewStorage.viewPrllx.destroy()

  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  if (globalStorage.menuOpen) globalStorage.closeMobileMenu()

  // Remove active links
  const navLinks = domStorage.menu.querySelectorAll('.Nav .Link')

  navLinks.forEach((link) => {
    if (link.href === globalStorage.taxi.targetLocation.raw) {
      link.classList.add('--active')
    } else {
      link.classList.remove('--active')
    }
  })

  // General modules
  if (modulesStorage.scroll_indicator) modulesStorage.scroll_indicator.destroy()
  if (modulesStorage.marquee) modulesStorage.marquee.destroy()
  if (modulesStorage.page_header) modulesStorage.page_header.destroy()
  if (modulesStorage.scroll_reveal_quote) modulesStorage.scroll_reveal_quote.destroy()
  if (modulesStorage.stack_cards) modulesStorage.stack_cards.destroy()
  if (modulesStorage.scroll_highlight_content) modulesStorage.scroll_highlight_content.destroy()
}

function initForms({DOM}) {
  FormsManager.init({
    debug: isDEVMODE,
    clearOnSuccess: true,
    DOM, // Si non renseigné, c'est le "document" complet qui sera utilisé
    forms: {
      contact: {
      }
    }
  });
}
