import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class ScrollIndicator {
  constructor() {
    this.DOM = {
      scrollIndicator: viewStorage.current.querySelector('.ScrollIndicator')
    }

    if (!this.DOM.scrollIndicator) return

    // Events
    this.onClick = this.onClick.bind(this)

    this.DOM.scrollIndicator.addEventListener('click', this.onClick)
  }

  onClick(e) {
    e && e.preventDefault()

    // Scroll to the 2nd section of the page
    this.tween = gsap.to(window, {
      scrollTo: viewStorage.current.children[1],
      ease: 'expo.inOut', 
      duration: 2
    })
  }
  
  destroy() {
    if (this.tween) {
      this.DOM.scrollIndicator.removeEventListener('click', this.onClick)
      this.tween.kill()
    }
  }
}
