import { globalStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

// import { homeIntro } from './home-intro'
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const brand = domStorage.intro.querySelector('.Brand')
  const layers = domStorage.intro.querySelectorAll('.layer')

  // Update body classNames
  domStorage.body.classList.remove('--preloading')
  domStorage.body.classList.remove('--loading')

  // Reset scroll with timing for Chrome
  setTimeout(() => window.scrollTo(0, 0), 25)

  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
    delay: 0.2,
    onComplete: () => {
      // Update firstLoad variable
      globalStorage.firstLoad = false

      // Remove intro from the DOM
      domStorage.intro.remove()
    }
  })

    tl.from(brand, { scale: 1.5, opacity: 0 }, 0)
    
    tl.to(brand, { yPercent: 50, scale: 2.5, duration: 1.5 }, 1.5)
    tl.to(layers, { clipPath: 'inset(0% 0% 100% 0%)', stagger: -0.075, duration: 1.5 }, 1.5)
    tl.to(domStorage.intro, { clipPath: 'inset(0% 0% 100% 0%)', duration: 1.5 }, 1.65)

    tl.add(pageIntro(1), 0)

    return tl
}
