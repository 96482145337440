import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'

export default class ArchiveArticles extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {}
  }

  onLeaveCompleted() {
  }
}
