import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class Timeline {
  constructor({ section }) {
    if (!section) return
    
    // DOM
    this.DOM = { section }
    this.DOM.title = this.DOM.section.querySelector('h2')
    this.DOM.scrollContainer = this.DOM.section.querySelector('.ScrollContainer')
    this.DOM.illustration = this.DOM.section.querySelector('.Illustration')
    this.DOM.svg = this.DOM.section.querySelector('.Illustration svg')
    this.DOM.linePath = this.DOM.section.querySelector('.Illustration svg > path')
    this.DOM.circles = this.DOM.section.querySelectorAll('.Illustration .circles > *')
    this.DOM.dates = this.DOM.section.querySelectorAll('.Illustration .date')

    if (!this.DOM.scrollContainer || !this.DOM.dates || !this.DOM.dates.length) return

    gsap.set(this.DOM.circles, { transformOrigin: '50% 50%' })

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setAnimation()
  }

  setAnimation() {
    this.titleTween = gsap.to(this.DOM.title, {
      yPercent: 150,
      opacity: 0,
      scale: 0.8,
      ease: 'none',
      scrollTrigger: {
        trigger: this.DOM.title,
        scrub: true,
        // markers: true
      }
    })

    // Infos: We need to use MatchMedia to change the xPercent pin on mobile/desktop
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        // Pin
        this.scrollTween = gsap.fromTo(this.DOM.illustration, {
          // xPercent: 20,
          xPercent: 5,
        }, {
          xPercent: -72,
          ease: 'none',
          scrollTrigger: {
            trigger: this.DOM.scrollContainer,
            pin: this.DOM.scrollContainer,
            pinSpacer: false,
            // pinSpacing: false,
            scrub: 0.1,
            start: 'center center',
            // start: 'top bottom',
            // end: 'bottom center',
            end: '+=5000',
            // markers: true
          }
        })

        // Main timeline line
        this.timelineLineTween = gsap.fromTo(this.DOM.linePath, {
          drawSVG: '0%'
        }, {
          drawSVG: '100%',
          ease: 'none',
          scrollTrigger: {
            trigger: this.DOM.linePath,
            containerAnimation: this.scrollTween,
            start: 'top 50%',
            end: 'bottom 50%',
            scrub: 0.1,
            id: 'Main_timeline_line',
            // markers: true
          }
        })

        // All dates
        this.DOM.dates.forEach((date, index) => {
          date.tl = gsap.timeline({
            defaults: {
              ease: 'expo.out',
              duration: 1,
            },
            scrollTrigger: {
              containerAnimation: this.scrollTween,
              trigger: date,
              start: 'top center',
              end: 'bottom bottom',
              toggleActions: 'play resume resume reset'
            }
          })

          if (index > 1) {
            date.tl.from(date, {
              opacity: 0,
              y: 50
            }, 0)

            date.tl.from(this.DOM.circles[index], {
              opacity: 0,
              scale: 0.5
            }, 0)
          }
        })
      } else {
        // Pin
        this.scrollTween = gsap.fromTo(this.DOM.illustration, {
          xPercent: 5,
        }, {
          xPercent: -90,
          ease: 'none',
          scrollTrigger: {
            trigger: this.DOM.scrollContainer,
            pin: this.DOM.scrollContainer,
            pinSpacer: false,
            // pinSpacing: false,
            scrub: 0.1,
            start: 'center center',
            // end: 'bottom center',
            end: '+=5000',
            // markers: true
          }
        })

        // Main timeline line
        this.timelineLineTween = gsap.fromTo(this.DOM.linePath, {
          drawSVG: '0%'
        }, {
          drawSVG: '100%',
          ease: 'none',
          scrollTrigger: {
            trigger: this.DOM.linePath,
            containerAnimation: this.scrollTween,
            start: 'top 50%',
            end: 'bottom 50%',
            scrub: 0.1,
            id: 'Main_timeline_line',
            // markers: true
          }
        })

        // All dates
        this.DOM.dates.forEach((date, index) => {
          date.tl = gsap.timeline({
            defaults: {
              ease: 'expo.out',
              duration: 1,
            },
            scrollTrigger: {
              containerAnimation: this.scrollTween,
              trigger: date,
              start: 'top center',
              end: 'bottom bottom',
              toggleActions: 'play resume resume reset'
            }
          })

          if (index > 1) {
            date.tl.from(date, {
              opacity: 0,
              y: 50
            }, 0)

            date.tl.from(this.DOM.circles[index], {
              opacity: 0,
              scale: 0.5
            }, 0)
          }
        })
      }
    })
  }

  /**
   * Destroy
   */
  destroy() {
    if (!this.DOM.scrollContainer) return
  
    // Kill all tweens
    this.titleTween && this.titleTween.kill()
    this.scrollTween.kill()
    this.timelineLineTween.kill()
    this.DOM.dates.forEach(date => date.tl && date.tl.kill())
  }
}
