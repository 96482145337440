import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import VideoBackground from '../modules/video-background'
import StackCards from '../animations/stack-cards'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import LineScrub from '../animations/line-scrub'

export default class FrontPage extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderHome'),
      presentationSection: viewStorage.current.querySelector('.PresentationCelineHocquet'),
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      relatedArticlesSwiper: viewStorage.current.querySelector('.RelatedArticles .swiper.--mobile'),
      contactSection: viewStorage.current.querySelector('.ContactSection')
    }
    
    if (this.DOM.pageHeader) this.videoBackgroundModule = new VideoBackground({ section: this.DOM.pageHeader })
    if (this.DOM.testimonialsSwiper) this.testimonialsSwiperModule = new WknSwiper(this.DOM.testimonialsSwiper)
    if (this.DOM.relatedArticlesSwiper) this.relatedArticlesSwiperModule = new MobileSwiper(this.DOM.relatedArticlesSwiper)

    if (this.DOM.presentationSection) this.presentationLineScrubModule = new LineScrub({ 
      section: this.DOM.presentationSection,
      options: {
        start: 'top center',
        end: 'bottom center',
      }
    })

    if (this.DOM.contactSection) this.presentationLineScrubModule = new LineScrub({
      section: this.DOM.contactSection,
      options: {
        start: 'top bottom',
        end: 'bottom center',
        from: '0%',
        to: '100%'
      }
    })
  }

  onLeaveCompleted() {
    if (this.videoBackgroundModule) this.videoBackgroundModule.destroy()
    if (this.presentationLineScrubModule) this.presentationLineScrubModule.destroy()
    if (this.testimonialsSwiperModule) this.testimonialsSwiperModule.destroy()
    if (this.relatedArticlesSwiperModule) this.relatedArticlesSwiperModule.destroy()
  }
}
