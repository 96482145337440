import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export default class ScrollRevealQuote {
  constructor() {
    this.DOM = { 
      scrollTexts: viewStorage.current.querySelectorAll('.ScrollRevealQuote p')
    }

    if (!this.DOM.scrollTexts && !this.DOM.scrollTexts.length) return

    this.DOM.scrollTexts.forEach(text => {
      // 1. Splitting for animating each word
      text.splittedWords = new SplitText(text, { type: 'words,chars' })

      // 2. Set all word opacity 0.3
      text.tween = gsap.set(text.splittedWords.chars, { opacity: 0.15 })

      // 3. Pin and animate
      text.tl = gsap.timeline({
        scrollTrigger: {
          // Section
          trigger: text.parentNode.parentNode.parentNode,
          start: 'top 70%',
          end: 'bottom 70%',
          scrub: true,
        }
      })
      .to(text.splittedWords.chars, { opacity: 1, stagger: 0.2, duration: 1.5, ease: 'expo.inOut' }, 0)
    })
  }

  destroy() {
    if (!this.DOM.scrollTexts && !this.DOM.scrollTexts.length) return

    this.DOM.scrollTexts.forEach(text => {
      if (text.splittedWords) text.splittedWords.revert() 
      if (text.tween) text.tween.kill()
      if (text.tl) text.tl.kill()
    })
  }
}
