import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class LineScrub {
  constructor({ section, options }) {
    if (!section) return

    this.options = options

    this.DOM = { section }
    this.DOM.line = this.DOM.section.querySelector('.Line')
    this.DOM.linePath = this.DOM.line.querySelector('path')

    if (!this.DOM.linePath) return

    this.setAnimation()
  }

  setAnimation() {
    this.tl = gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: this.DOM.section,
        start: this.options?.start ?? 'top center',
        end: this.options?.end ?? 'bottom center',
        scrub: true
      }
    })

    this.tl.fromTo(this.DOM.linePath, {
      drawSVG: this.options?.from ?? '100% 100%'
    }, {
      drawSVG: this.options?.to ?? '0% 100%'
    }, 0)
  }

  destroy() {
    if (this.tl) this.tl.kill()
  }
}
