import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ScrollHighlightContent{
  constructor() {
    // DOM
    this.DOM = { section: viewStorage.current.querySelector('.ScrollHighlightContent') }

    if (!this.DOM.section) return

    this.DOM.head = this.DOM.section.querySelector(':scope .container > .head')
    this.DOM.grid = this.DOM.section.querySelector(':scope .container > .grid')
    this.DOM.containers = this.DOM.section.querySelectorAll('.HighlightContentCardContainer')
    this.DOM.cards = this.DOM.section.querySelectorAll('.HighlightContentCard')

    if (!this.DOM.cards && !this.DOM.cards.length) return

    this.currentPage = 'service'

    if (viewStorage.current.dataset.taxiView === 'pageAbout') {
      this.currentPage = 'about'      
    }

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setAnimation()
  }  

  setAnimation() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        gsap.to(this.DOM.head, {
          yPercent: this.currentPage === 'service' ? 250 : 100,
          opacity: 0,
          scale: 0.8,
          scrollTrigger: {
            trigger: this.DOM.head,
            scrub: true,
            start: 'top 50%'
          }
        })

        this.DOM.containers.forEach((container, index) => {
          const length = this.DOM.containers.length
          const card = this.DOM.cards[index]

          container.tl = gsap.timeline({
            defaults: { ease: 'none' },
            scrollTrigger: {
              trigger: container,
              start: `top ${60 + 50 * index}`,
              end: 'bottom 20%',
              endTrigger: this.DOM.grid,
              scrub: true,
              pin: container,
              pinSpacing: false
            }
          })
 
         container.tl.to(card, {
           transformOrigin: 'top center',
           scale: () => {
            if (index < length - 1) {
              return 0 + 0.05 * index
            } else {
              return 0 + 0.25 * index
            }
           },
           yPercent: () => {
            if (this.currentPage === 'about') {
              return -300
            } else {
              return -500
            }
           },
           opacity: () => {
             if (index < length - 1) {
               return 0
             } else {
               return 1
             }
           }
         }, 0)
        })
      }
    })
  }

  destroy() {
    if (this.DOM.containers && this.DOM.containers.length) this.DOM.containers.forEach(container => container.tl && container.tl.kill())
  }
}
