import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageServiceChild extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      relatedServicesChildrenSwiper: viewStorage.current.querySelector('.RelatedServicesChildren .swiper.--mobile')
    }

    if (this.DOM.relatedServicesChildrenSwiper) this.relatedServicesChildrenSwiperModule = new MobileSwiper(this.DOM.relatedServicesChildrenSwiper)
  }

  onLeaveCompleted() {
    if (this.relatedServicesChildrenSwiperModule) this.relatedServicesChildrenSwiperModule.destroy()
  }
}
