import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Marquee {
  constructor() {
    this.DOM = { 
      sections: viewStorage.current.querySelectorAll('.MarqueeSection')
    }

    if (this.DOM.sections && !this.DOM.sections.length) return
    
    this.DOM.sections.forEach(section => {
      section.scrollTrigger = ScrollTrigger.create({
        trigger: section,
        start: 'top bottom',
        end: 'bottom top',
        onEnter: () => section.classList.add('--play'),
        onLeave: () => section.classList.remove('--play'),
        onEnterBack: () => section.classList.add('--play'),
        onLeaveBack: () => section.classList.remove('--play')
      })
    })
  }

  destroy() {
    if (this.DOM.sections && !this.DOM.sections.length) return

    this.DOM.sections.forEach(section => {
      if (section.scrollTrigger) section.scrollTrigger.kill()
    })
  }
}
