import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class PageHeader {
  constructor() {
    if (!viewStorage.current.querySelector('.PageHeader')) return

    // DOM
    this.DOM = { pageHeader: viewStorage.current.querySelector('.PageHeader') }
    this.DOM.container = this.DOM.pageHeader.querySelector('.container')
    this.DOM.visual = this.DOM.pageHeader.querySelector('.visual__container')

    // Timeline
    this.tl = gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: this.DOM.pageHeader,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      }
    })

    this.tl.to(this.DOM.container, { scale: 0.8, yPercent: 100 }, 0)
    this.tl.to(this.DOM.visual, { scale: 1.1 }, 0)
  }

  /**
   * Destroy
   */

  destroy() {
    if (this.tl) this.tl.kill()
  }
}
