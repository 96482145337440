import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

/**
 * All animations for different parts of the DOM
 */

export const header = () => {
  if (!domStorage.header) return

  const tween = gsap.fromTo(domStorage.header, {
    yPercent: -30,
    opacity: 0,
  }, {
    yPercent: 0,
    opacity: 1,
    ease: 'expo.inOut',
    duration: 1.5,
    onComplete: () => {
      gsap.set(domStorage.header, { clearProps: 'yPercent,scale,opacity' })
    }
  })

  return tween
}

export const basicIntro = () => {
  const children = [...viewStorage.current.children('.container > *:not(.head)')]

  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
    onComplete: () => {
      if (children) gsap.set(children, { clearProps: 'y,opacity' })
    }
  })

  if (children && children.length) tl.from(children, { y: 100, opacity: 0 }, 0.035)

  return tl
}

export const pageHeaderIntro = () => {
  const pageHeader = viewStorage.current.children[0]

  if (!pageHeader) return

  /**
   * DOM
   */
  const h1 = pageHeader.querySelector('h1')
  const breadcrumb = pageHeader.querySelector('.Breadcrumb')
  const content = pageHeader.querySelector('.wswyg--content')
  const p = pageHeader.querySelector('p')
  const btn = pageHeader.querySelector('.Btn')
  const scrollIndicator = pageHeader.querySelector('.ScrollIndicator')
  const scrollIndicatorCircle = pageHeader.querySelector('.ScrollIndicator circle')
  const scrollIndicatorArrow = pageHeader.querySelector('.ScrollIndicator .arrow')
  const backgroundVisual = pageHeader.querySelector(':scope > .visual__container .visual')
  const sections = [...viewStorage.current.children].slice(1)

  /**
   * Main Timeline
   */
  const tl = gsap.timeline({
    defaults: { ease: 'power3.inOut', duration: 1 },
  })

  /**
   * General
   */

  if (h1) {
    tl.from(h1, { 
      y: 40,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0)
  }

  if (breadcrumb) {
    tl.from(breadcrumb, { 
      y: -40,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0.2)
  }

  if (content) {
    tl.from(content, { 
      y: 40,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0.075)
  }

  if (p) {
    tl.from(p, { 
      y: 40,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0.075)
  }

  if (btn) {
    tl.from(btn, { 
      y: 40,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0.075)
  }

  if (scrollIndicator) {
    tl.fromTo(scrollIndicatorCircle, { drawSVG: '0%' }, { drawSVG: '100%', ease: 'expo.inOut', duration: 1.5 }, 1)
    tl.from(scrollIndicatorArrow, { y: -10, opacity: 0, ease: 'expo.inOut', duration: 1.5 }, 1.15)
  }

  if (backgroundVisual) {
    tl.from(backgroundVisual, { 
      scale: 1.15,
    }, '-=1')
  }

  return tl
}

// Page: Basic
export const pageSections = () => {
  const sections = [...viewStorage.current.children].slice(1)

  if (!sections && !sections.length) return

  const tween = gsap.from(sections, { 
    y: 20, 
    opacity: 0, 
    ease: 'power3.out', 
    duration: 1, 
    onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
  })

  return tween
}
