import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Test {
  constructor({ section }) {
    if (!section) return

    this.DOM = { section }
    this.DOM.video = this.DOM.section.querySelector('video')

    this.setVideoLazyload()
    this.toggleVideoOnScroll()
  }

  setVideoLazyload() {
    const videoSource = this.DOM.video.children[0]

    videoSource.src = videoSource.dataset.src

    this.DOM.video.load()
    this.DOM.video.play()
  }

  toggleVideoOnScroll() {
    const playVideo = () => this.DOM.video.play()
    const pauseVideo = () => this.DOM.video.pause()

    this.scrollTrigger = ScrollTrigger.create({
      trigger: this.DOM.section,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: () => playVideo(),
      onLeave: () => pauseVideo(),
      onEnterBack: () => playVideo(),
      onLeaveBack: () => pauseVideo(),
    })
  }

  resetVideoLazyLoad() {
    const videoSource = this.DOM.video.children[0]

    videoSource.removeAttribute('src')

    this.DOM.video.pause()
    this.DOM.video.currentTime = 0
  }

  destroy() {
    if (!this.DOM.section) return

    this.scrollTrigger.kill()
    this.resetVideoLazyLoad()
  }
}
