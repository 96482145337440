import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import LineScrub from '../animations/line-scrub'
import WknSwiper from '../modules/wkn-swiper'
import Timeline from '../animations/timeline'

export default class PageAbout extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      ourVisionSection: viewStorage.current.querySelector('.OurVision'),
      aboutContentSection: viewStorage.current.querySelector('.AboutContent.--with-line'),
      ourCertificationsSection: viewStorage.current.querySelector('.OurCertifications'),
      ourCertificationsSwiper: viewStorage.current.querySelector('.OurCertifications .swiper'),
      timelineSection: viewStorage.current.querySelector('.Timeline')
    }
    
    if (this.DOM.ourCertificationsSwiper) this.ourCertificationsSwiperModule = new WknSwiper(this.DOM.ourCertificationsSwiper, { slidesPerView: 'auto', centerInsufficientSlides: true })

    if (this.DOM.ourVisionSection) this.ourVisionLineModule = new LineScrub({ 
      section: this.DOM.ourVisionSection,
      options: {
        start: 'top center',
        end: 'bottom center',
      }
    })

    if (this.DOM.aboutContentSection) this.aboutContentLineModule = new LineScrub({ 
      section: this.DOM.aboutContentSection,
      options: {
        start: 'top center',
        end: 'bottom center',
      }
    })

    if (this.DOM.ourCertificationsSection) this.ourCertificationsLineModule = new LineScrub({ 
      section: this.DOM.ourCertificationsSection,
      options: {
        start: 'top center',
        end: 'bottom center',
      }
    })

    if (this.DOM.timelineSection) this.timelineModule = new Timeline({ section: this.DOM.timelineSection })
  }

  onLeaveCompleted() {
    if (this.ourVisionLineModule) this.ourVisionLineModule.destroy()
    if (this.aboutContentLineModule) this.aboutContentLineModule.destroy()
    if (this.ourCertificationsLineModule) this.ourCertificationsLineModule.destroy()
    if (this.ourCertificationsSwiperModule) this.ourCertificationsSwiperModule.destroy()
    if (this.timelineModule) this.timelineModule.destroy()
  }
}
